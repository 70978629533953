<template>
  <div class="archives-container">
    <Header />
    <el-main>
      <el-row justify="center" align="middle">
        <el-col :xs="20" :sm="18" :md="14" :lg="10" :xl="10">
          <div class="archives">
            <div class="desc">
              太棒了! 目前共计 {{ page.pageCount }} 篇文章。 继续努力！
            </div>
            <div
              class="article-list"
              v-for="(item, index) in archives"
              :key="index"
            >
              <div v-for="(value, key) in item" :key="key">
                <div class="collection-title">
                  <h2 class="archive-year">{{ key }}</h2>
                </div>
                <article
                  class="article"
                  v-for="(article, index) in value"
                  :key="index"
                >
                  <header class="article-header">
                    <div class="article-meta">
                      <time class="article-time"
                        ><i class="iconfont icon-rili"></i>
                        {{ article.month }} - {{ article.day }}</time
                      >
                    </div>
                    <h3 class="article-title">
                      <a
                        href="javascript: void(0)"
                        @click="linkToArticle(article)"
                      >
                        <span>{{ article.title }}</span>
                      </a>
                    </h3>
                  </header>
                </article>
              </div>
            </div>
          </div>
          <div class="hole-go">
            <i class="fa fa-paper-plane fa-lg"></i>
          </div>
        </el-col>
      </el-row>
      <div class="pagination fadeInUp animated visible">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page="page.curPage"
          :total="page.pageCount"
          :page-size="page.pageSize"
          :pager-count="3"
          v-show="page.pageCount > page.pageSize"
        ></el-pagination>
      </div>
    </el-main>
  </div>
</template>

<script>
import Header from "@/components/common/header";
import { listArchives } from "@/api/article";
export default {
  name: "index",
  components: {
    Header,
  },
  data() {
    return {
      archives: [],
      page: {
        curPage: 1,
        pageSize: 10,
        pageCount: 0,
      },
    };
  },
  created() {
    this.getArchivesList();
  },
  methods: {
    async getArchivesList() {
      await listArchives(this.page)
        .then((res) => {
          if (res.code == 200) {
            this.archives = res.data.archives;
            this.page.pageCount = res.data.count;
          } else {
            this.archives = {};
            this.page.pageCount = 0;
          }
        })
        .catch(() => {
          this.archives = {};
          this.page.pageCount = 0;
        });
    },
    handleCurrentChange: function (currentPage) {
      //回到最顶部
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      //修改页数
      this.page.curPage = currentPage;
      this.getArchivesList();
    },
    linkToArticle(item) {
      if (item.customLink) {
        this.$router.push("/article/" + item.customLink + ".html");
      } else {
        this.$router.push("/article/" + item.link);
      }
    },
  },
};
</script>

<style scoped>
.archives-container {
  width: 100%;
  height: 100%;
  color: var(--text-color);
}
.archives::after {
  content: " ";
  position: absolute;
  top: 0;
  margin-left: -2px;
  width: 4px;
  height: 100%;
  background: var(--border-color);
  z-index: -1;
  transition: opacity 0.2s;
}
.archives .desc {
  margin-left: 20px;
}
.archives .article-list {
  position: relative;
  z-index: 99;
}
.archives .article-list .collection-title {
  position: relative;
  margin: 2rem 0;
}
.archives .article-list .collection-title::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 50%;
  margin-left: -10px;
  margin-top: -9px;
  width: 16px;
  height: 16px;
  background: var(--background-color);
  border-radius: 50%;
  border: 2px solid #ccc;
  transition: opacity 0.2s, slowDown 0.4s;
}
.archives .article-list .collection-title:hover::before {
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  box-shadow: 0 0.125rem 1.25rem var(--primary-color);
}
.archives .article-list .collection-title .archive-year {
  display: inline-block;
  margin-left: 2rem;
}
.archives .article-list .article {
  position: relative;
  margin: 30px 0;
}
.archives .article-list .article::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 50%;
  margin-left: -10px;
  margin-top: -9px;
  width: 16px;
  height: 16px;
  background: var(--background-color);
  border-radius: 50%;
  border: 2px solid #ccc;
  transition: opacity 0.2s, slowDown 0.4s;
}
.archives .article-list .article:hover::before {
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  box-shadow: 0 0.125rem 1.25rem var(--primary-color);
}
.archives .article-list .article .article-header {
  position: relative;
  box-shadow: 0 1px 18px -6px var(--shadow-color);
  padding: 1rem;
  margin-left: 2rem;
  border-radius: 0.625rem;
  background: var(--post-color);
  transform-style: preserve-3d;
  transition: ease-in-out 0.3s;
}
.archives .article-list .article:hover .article-header {
  transform: rotateX(360deg);
}
.archives .article-list .article .article-header::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  margin-left: -20px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent var(--post-color) transparent;
  transform: translateY(-50%) rotate(-90deg);
  transition: ease-in-out 0.3s;
}
.archives .article-list .article .article-header .article-title a {
  text-decoration: none;
}
.archives .article-list .article .article-header .article-title {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: inherit;
  display: inline-block;
}
.archives .article-list .article .article-header .article-meta {
  font-size: 12px;
  display: inline-block;
  margin-left: 10px;
}

.archives .article-list .article:hover > .article-header a,
.archives .article-list .article:hover > .article-header .article-meta {
  color: #fff;
}

.archives .article-list .article:nth-of-type(1):hover .article-header {
  background-color: #1abc9c;
  box-shadow: 0 0.125rem 1.25rem #1abc9c;
}
.archives .article-list .article:nth-of-type(1):hover .article-header::before {
  border-color: transparent transparent #1abc9c transparent;
}

.archives .article-list .article:nth-of-type(2):hover .article-header {
  background-color: #2ecc71;
  box-shadow: 0 0.125rem 1.25rem #2ecc71;
}
.archives .article-list .article:nth-of-type(2):hover .article-header::before {
  border-color: transparent transparent #2ecc71 transparent;
}

.archives .article-list .article:nth-of-type(3):hover .article-header {
  background-color: #3498db;
  box-shadow: 0 0.125rem 1.25rem #3498db;
}
.archives .article-list .article:nth-of-type(3):hover .article-header::before {
  border-color: transparent transparent #3498db transparent;
}

.archives .article-list .article:nth-of-type(4):hover .article-header {
  background-color: #9b59b6;
  box-shadow: 0 0.125rem 1.25rem #9b59b6;
}
.archives .article-list .article:nth-of-type(4):hover .article-header::before {
  border-color: transparent transparent #9b59b6 transparent;
}

.archives .article-list .article:nth-of-type(5):hover .article-header {
  background-color: #34495e;
  box-shadow: 0 0.125rem 1.25rem #34495e;
}
.archives .article-list .article:nth-of-type(5):hover .article-header::before {
  border-color: transparent transparent #34495e transparent;
}

.archives .article-list .article:nth-of-type(6):hover .article-header {
  background-color: #f1c40f;
  box-shadow: 0 0.125rem 1.25rem #f1c40f;
}
.archives .article-list .article:nth-of-type(6):hover .article-header::before {
  border-color: transparent transparent #f1c40f transparent;
}

.archives .article-list .article:nth-of-type(7):hover .article-header {
  background-color: #e67e22;
  box-shadow: 0 0.125rem 1.25rem #e67e22;
}
.archives .article-list .article:nth-of-type(7):hover .article-header::before {
  border-color: transparent transparent #e67e22 transparent;
}

.archives .article-list .article:nth-of-type(8):hover .article-header {
  background-color: #e74c3c;
  box-shadow: 0 0.125rem 1.25rem #e74c3c;
}
.archives .article-list .article:nth-of-type(8):hover .article-header::before {
  border-color: transparent transparent #e74c3c transparent;
}

.archives .article-list .article:nth-of-type(9):hover .article-header {
  background-color: #000;
  box-shadow: 0 0.125rem 1.25rem #000;
}
.archives .article-list .article:nth-of-type(9):hover .article-header::before {
  border-color: transparent transparent #000 transparent;
}

.archives .article-list .article:nth-of-type(10):hover .article-header {
  background-color: #8ac6d1;
  box-shadow: 0 0.125rem 1.25rem #8ac6d1;
}
.archives .article-list .article:nth-of-type(10):hover .article-header::before {
  border-color: transparent transparent #8ac6d1 transparent;
}

.archives-container .pagination {
  width: 100%;
  padding: 1.25rem 0;
  text-align: center;
  display: inline-block;
  opacity: 0;
}

.hole-go {
  font-size: 20px;
  color: rgba(3, 169, 244, 0.7);
  font-weight: 700;
  margin: 20px auto;
  position: absolute;
  transform: translateX(-15px);
}
</style>
